import React, { ReactElement, useMemo } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import SelectDropdown from '@/library/components/atoms/SelectDropdown';
import OverwriteReasonModal from '@/library/components/organisms/OverwriteReasonModal';
import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  editWORecommendation,
  fetchTechniciansConfigurationForWO,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { InlineOverwriteTriggerButton } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/InlineOverwriteTriggerButton';
import {
  constructTechConfigurationQueryParams,
  getSectionizedFormattedListOfTechnicians,
} from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

interface iTechnicianOverwriteInputProps {
  woData: iFormattedRecommendationsData;
  secondaryTechnicianIndex?: number;
}

export function TechnicianOverwriteInput(
  props: iTechnicianOverwriteInputProps,
): ReactElement {
  const { woData, secondaryTechnicianIndex } = props;

  const {
    isFetchingTechniciansForDate,
    fetchTechniciansForDateError,
    techniciansConfigListForDate,
    shiftLaborRollupData,
  } = useAppSelector(state => state.scheduleWODataReducer);
  const { ottoAppConfig } = useAppSelector(state => state.masterDataReducer);

  const dispatch = useAppDispatch();

  const updateWOData = (woData): void => {
    dispatch(editWORecommendation(woData));
  };

  const woOwnerError =
    !woData.scheduledStartDate ||
    !!fetchTechniciansForDateError[woData.workOrderID]?.[
      woData.scheduledStartDate
    ];
  const woOwnerErrorMessage = !woData.scheduledStartDate
    ? uiStrings.selectStartDateFirst
    : fetchTechniciansForDateError[woData.workOrderID]?.[
        woData.scheduledStartDate
      ];
  const isLoadingTechniciansList =
    isFetchingTechniciansForDate[woData.workOrderID]?.[
      woData.scheduledStartDate
    ];

  const isSecondaryTechnician = typeof secondaryTechnicianIndex === 'number';

  const sectionizedFormattedList = useMemo(
    () =>
      getSectionizedFormattedListOfTechnicians(
        techniciansConfigListForDate[woData.workOrderID]?.[
          woData.scheduledStartDate
        ],
        woData.assets,
        isSecondaryTechnician
          ? woData.secondaryOwnersShifts[secondaryTechnicianIndex]
          : woData.shift,
        shiftLaborRollupData[woData.scheduledStartDate],
        isSecondaryTechnician
          ? [
              woData.workOrderOwner,
              ...woData.secondaryOwners.slice(0, secondaryTechnicianIndex),
              ...woData.secondaryOwners.slice(secondaryTechnicianIndex + 1),
            ]
          : woData.secondaryOwners,
      ),
    [
      techniciansConfigListForDate,
      woData,
      secondaryTechnicianIndex,
      shiftLaborRollupData,
    ],
  );

  const techId = isSecondaryTechnician
    ? woData.secondaryOwners?.[secondaryTechnicianIndex]
    : woData.workOrderOwner;

  const onOverwriteValue = (
    newValue,
    overwriteReasons,
    overwriteReasonComment,
  ): void => {
    const shift = techniciansConfigListForDate[woData.workOrderID]?.[
      woData.scheduledStartDate
    ]?.find(tech => tech.technicianId === newValue.value)?.shiftCode;

    if (isSecondaryTechnician) {
      const updatedSecondaryOwners: string[] = [];
      const updatedSecondaryOwnersShifts: Array<string | undefined> = [];
      for (let i = 0; i < woData.requiredTechniciansCount - 1; i++) {
        if (secondaryTechnicianIndex === i) {
          updatedSecondaryOwners.push(newValue.value);
          updatedSecondaryOwnersShifts.push(shift);
        } else {
          updatedSecondaryOwners.push(woData.secondaryOwners[i]);
          updatedSecondaryOwnersShifts.push(woData.secondaryOwnersShifts[i]);
        }
      }
      updateWOData({
        ...woData,
        secondaryOwners: updatedSecondaryOwners,
        secondaryOwnersShifts: updatedSecondaryOwnersShifts,
        overwriteReasons,
        overwriteReasonComment,
      });
    } else {
      const shift = techniciansConfigListForDate[woData.workOrderID]?.[
        woData.scheduledStartDate
      ]?.find(tech => tech.technicianId === newValue.value)?.shiftCode;
      updateWOData({
        ...woData,
        shift: shift ?? woData.shift,
        workOrderOwner: newValue.value,
        overwriteReasons,
        overwriteReasonComment,
      });
    }
  };

  const fetchTechniciansConfiguration = (): void => {
    if (
      woData.scheduledStartDate &&
      !techniciansConfigListForDate[woData.workOrderID]?.[
        woData.scheduledStartDate
      ]?.length
    ) {
      dispatch(
        fetchTechniciansConfigurationForWO(
          constructTechConfigurationQueryParams(woData),
        ),
      );
    }
  };

  const triggerButton = (toggleModal): ReactElement => (
    <InlineOverwriteTriggerButton value={techId}>
      <SelectDropdown
        buttonType="inline"
        selectedOption={{
          label: techId,
          value: techId,
        }}
        onChange={({ detail }) => {
          if (woData.overwriteReasons?.length) {
            onOverwriteValue(
              { value: detail.selectedOption.value },
              woData.overwriteReasons,
              woData.overwriteReasonComment,
            );
          } else {
            if (!woData.recommendedScheduledStartDate) {
              onOverwriteValue(
                { value: detail.selectedOption.value },
                [appConstants.unassignedWOOverwriteReason],
                woData.unassignedReason,
              );
            } else {
              toggleModal(detail.selectedOption.value);
            }
          }
        }}
        options={
          !woOwnerError
            ? [
                {
                  label: '',
                  options: [
                    {
                      label: uiStrings.unassignWorkOrder,
                      value: '',
                    },
                  ],
                },
                ...sectionizedFormattedList,
              ]
            : []
        }
        loading={isLoadingTechniciansList}
        error={woOwnerError}
        errorText={woOwnerErrorMessage}
        onLoadItems={fetchTechniciansConfiguration}
      />
    </InlineOverwriteTriggerButton>
  );

  return (
    <OverwriteReasonModal
      woId={woData.workOrderID}
      onOverwriteValue={onOverwriteValue}
      overwriteFieldLabel={uiStrings.technician}
      currentValue={techId}
      triggerButton={triggerButton}
      overwriteReasons={ottoAppConfig.overwriteReasonsList}
    />
  );
}
