import React, { ReactElement } from 'react';

import Button from '@/library/components/atoms/Button';
import Icon from '@/library/components/atoms/Icon';
import Tooltip from '@/library/components/molecules/Tooltip';
import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { formatDate, getUTCDateByDateString } from '@/library/utils/dateUtils';
import { AppRegions, getCurrentRegion } from '@/library/utils/environmentUtils';
import { roundOffToDecimalPlaces } from '@/library/utils/numberUtils';
import { iWOTablePreferencesContentDisplay } from '@/pages/Administration/administrationUtils';
import TableColumnHeader from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableColumnHeader/TableColumnHeader';
import { ScheduleStartDateOverwriteInput } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/ScheduleStartDateOverwriteInput';
import { SchedulingBlockOverwriteInput } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/SchedulingBlockOverwriteInput';
import { ShiftLaborRollup } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/ShiftLaborRollup';
import { ShiftOverwriteInputList } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/ShiftOverwriteInputList';
import { TechnicianOverwriteInput } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/TechnicianOverwriteInput';
import { WorkOrderErrorBadge } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/WorkOrderErrorBadge';
import { WorkOrderId } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/WorkOrderId';

export const tooltipCell = (text, maxCharCount = 50): ReactElement => (
  <Tooltip
    content={<p className="start-session-instructions-text">{text}</p>}
    position="top"
  >
    {text?.length > maxCharCount ? `${text.slice(0, maxCharCount)}...` : text}
  </Tooltip>
);

export const tooltipHeader = (label, tooltipText): ReactElement => (
  <div>
    {label}
    <span onClick={e => e.stopPropagation()}>
      <Tooltip
        triggerType="custom"
        content={
          <p className="start-session-instructions-text">{tooltipText}</p>
        }
        position="top"
      >
        <Button
          iconName="status-info"
          variant="icon"
        />
      </Tooltip>
    </span>
  </div>
);

export const tableColumnConfig = {
  errors: {
    id: 'errors',
    minWidth: 120,
    cell: e => <WorkOrderErrorBadge woData={e} />,
  },
  schStartDate: {
    id: 'scheduledStartDate',
    header: (
      <TableColumnHeader
        headerText={uiStrings.schStartDate}
        sortingComparator={(a, b) => {
          if (a.scheduledStartDate === b.scheduledStartDate) return 0;
          if (!a.scheduledStartDate) return -1;
          if (!b.scheduledStartDate) return 1;

          return new Date(a.scheduledStartDate).getTime() >
            new Date(b.scheduledStartDate).getTime()
            ? 1
            : -1;
        }}
        columnId="scheduledStartDate"
      />
    ),
    minWidth: 170,
    cell: e => <ScheduleStartDateOverwriteInput woData={e} />,
  },
  shift: {
    id: 'shift',
    header: (
      <TableColumnHeader
        headerText={uiStrings.shift}
        sortingField="shift"
        columnId="shift"
      />
    ),
    minWidth: 130,
    cell: e => <ShiftOverwriteInputList woData={e} />,
  },
  dueDate: {
    id: 'dueDate',
    header: (
      <TableColumnHeader
        headerText={uiStrings.dueDate}
        sortingComparator={(a, b) => {
          if (a.dueDate === b.dueDate) return 0;
          if (!a.dueDate) return -1;
          if (!b.dueDate) return 1;

          return new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()
            ? 1
            : -1;
        }}
        columnId="dueDate"
      />
    ),
    minWidth: 130,
    cell: e =>
      e.dueDate
        ? formatDate(
            getUTCDateByDateString(e.dueDate),
            appConstants.uiDateFormat,
          )
        : '',
  },
  woStatus: {
    id: 'woStatus',
    minWidth: 150,
    cell: e => e.woStatus,
    header: (
      <TableColumnHeader
        headerText={uiStrings.woStatus}
        sortingField="woStatus"
        columnId="woStatus"
      />
    ),
  },
  primaryOwner: {
    id: 'workOrderOwner',
    minWidth: 150,
    cell: e => <TechnicianOverwriteInput woData={e} />,
    header: (
      <TableColumnHeader
        headerText={uiStrings.assignedTo}
        sortingField="workOrderOwner"
        columnId="workOrderOwner"
      />
    ),
  },
  secondaryOwner: {
    id: 'secondaryOwners',
    header: uiStrings.additionalTechnicians,
    minWidth: 200,
    cell: e =>
      e.requiredTechniciansCount > 1 && (
        <div>
          <p className="tech-required-count">
            {e.requiredTechniciansCount - 1 + ' ' + uiStrings.required}
          </p>

          {[...Array(e.requiredTechniciansCount - 1).keys()].map(index => {
            return (
              <div
                key={
                  (e.secondaryOwnersShifts[index] ?? '') +
                  (e.secondaryOwners[index] ?? '') +
                  index
                }
                className="additional-technician-shift-dropdown"
              >
                <TechnicianOverwriteInput
                  woData={e}
                  secondaryTechnicianIndex={index}
                />
              </div>
            );
          })}
        </div>
      ),
  },
  schedulingBlock: {
    id: 'schedulingBlock',
    minWidth: 180,
    cell: e => <SchedulingBlockOverwriteInput woData={e} />,
    header: (
      <TableColumnHeader
        headerText={uiStrings.woExecution}
        sortingField="schedulingBlock"
        columnId="schedulingBlock"
      />
    ),
  },
  workOrderDesc: {
    id: 'workOrderDesc',
    minWidth: 250,
    cell: e => tooltipCell(e.workOrderDesc),
    header: (
      <TableColumnHeader
        headerText={uiStrings.woDescription}
        sortingField="workOrderDesc"
        columnId="workOrderDesc"
      />
    ),
  },
  estimatedHours: {
    id: 'estimatedHours',
    cell: e => roundOffToDecimalPlaces(e.estimatedHours, 2),
    minWidth: 120,
    header: (
      <TableColumnHeader
        headerText={uiStrings.estHrs}
        sortingField="estimatedHours"
        columnId="estimatedHours"
      />
    ),
  },
  shiftLaborRollup: {
    id: 'shiftLaborRollup',
    header: tooltipHeader(
      uiStrings.shiftLaborRollup,
      uiStrings.shiftLaborRollupHeaderDescription,
    ),
    minWidth: 190,
    cell: e => <ShiftLaborRollup woData={e} />,
  },
  ottoStatus: {
    id: 'ottoStatus',
    minWidth: 170,
    cell: e => {
      return (
        <span className="otto-status">
          <Icon
            name={e.ottoStatusIcon}
            variant={
              e.ottoStatus === 'ENDORSED'
                ? 'success'
                : e.ottoStatus === 'ENDORSEMENT_FAILED'
                ? 'error'
                : 'normal'
            }
          />
          {e.ottoStatus}
        </span>
      );
    },
    header: (
      <TableColumnHeader
        headerText={tooltipHeader(
          uiStrings.ottoStatus,
          <>
            <p>{uiStrings.ottoStatusEndorsedHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusEndorsementPendingHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusEndorsementFailedHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusChangeRecommendedHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusNewHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusUnassignedHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusExcludedHeaderDescription}</p>
            <br />
            <p>{uiStrings.ottoStatusClosedCancelledHeaderDescription}</p>
          </>,
        )}
        sortingField="ottoStatus"
        columnId="ottoStatus"
      />
    ),
  },
  workOrderID: {
    id: 'workOrderID',
    minWidth: 180,
    cell: e => <WorkOrderId workOrderDetails={e} />,
    header: (
      <TableColumnHeader
        headerText={uiStrings.woId}
        sortingField="workOrderID"
        columnId="workOrderID"
      />
    ),
  },
  equipmentAlias: {
    id: 'equipmentAlias',
    minWidth: 170,
    cell: e => e.equipmentAlias,
    header: (
      <TableColumnHeader
        headerText={uiStrings.equipmentAlias}
        sortingField="equipmentAlias"
        columnId="equipmentAlias"
      />
    ),
  },
  equipmentDescription: {
    id: 'equipmentDescription',
    minWidth: 220,
    cell: e => tooltipCell(e.equipmentDescription),
    header: (
      <TableColumnHeader
        headerText={uiStrings.equipmentDescription}
        sortingField="equipmentDescription"
        columnId="equipmentDescription"
      />
    ),
  },
  criticality: {
    id: 'criticality',
    minWidth: 130,
    cell: e => e.criticality,
    header: (
      <TableColumnHeader
        headerText={uiStrings.criticality}
        sortingField="criticality"
        columnId="criticality"
      />
    ),
  },
  precedenceScore: {
    id: 'precedenceScore',
    minWidth: 210,
    cell: e => e.precedence,
    header: (
      <TableColumnHeader
        headerText={tooltipHeader(
          uiStrings.precedenceScore,
          uiStrings.precedenceHeaderDescription,
        )}
        sortingField="precedence"
        columnId="precedenceScore"
      />
    ),
  },
  woType: {
    id: 'woType',
    header: (
      <TableColumnHeader
        headerText={uiStrings.woType}
        sortingField="woType"
        columnId="woType"
      />
    ),
    minWidth: 150,
    cell: e => e.woType,
  },
  unassignedReason: {
    id: 'unassignedReason',
    minWidth: 210,
    cell: e => tooltipCell(e.unassignedReason),
    header: (
      <TableColumnHeader
        headerText={tooltipHeader(
          uiStrings.unassignedReason,
          uiStrings.unassignedReasonHeaderDescription,
        )}
        sortingField="unassignedReason"
        columnId="unassignedReason"
      />
    ),
  },
  assets: {
    id: 'assets',
    minWidth: 170,
    cell: e => tooltipCell(e.assets.join(', ') || '-', 20),
    header: (
      <TableColumnHeader
        headerText={uiStrings.tacAssets}
        sortingField="assets"
        columnId="assets"
      />
    ),
  },
  requiredPreferences: {
    id: 'requiredPreferences',
    minWidth: 210,
    cell: e => tooltipCell((e.requiredPreferences ?? []).join(', ') || '-', 20),
    header: (
      <TableColumnHeader
        headerText={uiStrings.requiredPreferences}
        sortingField="requiredPreferences"
        columnId="requiredPreferences"
      />
    ),
  },
  requiredTechniciansCount: {
    id: 'requiredTechniciansCount',
    cell: e => e.requiredTechniciansCount,
    minWidth: 200,
    header: (
      <TableColumnHeader
        headerText={uiStrings.techniciansRequired}
        sortingField="requiredTechniciansCount"
        columnId="requiredTechniciansCount"
      />
    ),
  },
  equipmentZone: {
    id: 'equipmentZone',
    cell: e => e.equipmentZone,
    minWidth: 200,
    header: (
      <TableColumnHeader
        headerText={uiStrings.equipmentZone}
        sortingField="equipmentZone"
        columnId="equipmentZone"
      />
    ),
  },
};

export const getInitialTableContentPreference =
  (): iWOTablePreferencesContentDisplay[] => {
    const preferences = [
      {
        id: 'schStartDate',
        label: uiStrings.schStartDate,
        alwaysVisible: true,
        visible: true,
      },
      {
        id: 'shift',
        label: uiStrings.shift,
        alwaysVisible: true,
        visible: true,
      },
      {
        id: 'dueDate',
        label: uiStrings.dueDate,
        visible: true,
      },
      { id: 'woStatus', label: uiStrings.woStatus, visible: true },
      {
        id: 'primaryOwner',
        label: uiStrings.assignedTo,
        alwaysVisible: true,
        visible: true,
      },
      {
        id: 'secondaryOwner',
        label: uiStrings.additionalTechnicians,
        alwaysVisible: true,
        visible: true,
      },
      {
        id: 'schedulingBlock',
        label: uiStrings.woExecution,
        alwaysVisible: true,
        visible: true,
      },
      { id: 'workOrderDesc', visible: true, label: uiStrings.woDescription },
      { id: 'estimatedHours', visible: true, label: uiStrings.estHrs },
      {
        id: 'shiftLaborRollup',
        visible: true,
        label: uiStrings.shiftLaborRollup,
        alwaysVisible: true,
      },
      { id: 'ottoStatus', visible: true, label: uiStrings.ottoStatus },
      { id: 'workOrderID', visible: true, label: uiStrings.woId },
      { id: 'equipmentAlias', visible: true, label: uiStrings.equipmentAlias },
      {
        id: 'equipmentDescription',
        label: uiStrings.equipmentDescription,
        visible: true,
      },
      {
        id: 'criticality',
        label: uiStrings.criticality,
        visible: true,
      },
      {
        id: 'precedenceScore',
        visible: true,
        label: uiStrings.precedenceScore,
      },
      { id: 'woType', visible: true, label: uiStrings.woType },
      {
        id: 'unassignedReason',
        visible: true,
        label: uiStrings.unassignedReason,
      },
      {
        id: 'requiredTechniciansCount',
        label: uiStrings.techniciansRequired,
        visible: true,
      },
      { id: 'assets', visible: true, label: uiStrings.tacAssets },
      {
        id: 'equipmentZone',
        visible: true,
        label: uiStrings.equipmentZone,
      },
    ];

    getCurrentRegion() !== AppRegions.EU &&
      preferences.push({
        id: 'requiredPreferences',
        visible: true,
        label: uiStrings.requiredPreferences,
      });

    return preferences;
  };
