export interface iWOTablePreferencesContentDisplay {
  id: string;
  label: string;
  alwaysVisible?: boolean;
  visible: boolean;
}

export interface iWOTablePreferences {
  pageSize: number;
  contentDisplay: iWOTablePreferencesContentDisplay[];
}

export interface iConfigData {
  adminTabsAuthorisedGroups: string[];
  overviewTabAuthorisedGroups: string[];
  scheduleWOTabAuthorisedGroups: string[];
  schedulingBlockOverwriteReasons: string[];
  ignoredErrorCodes: string[];
  overwriteReasonsList: string[];
  ottoEnabledSites: string[];
  scheduleWorkOrdersTabEnabledSites: string[];
  noOfCardsOnOverviewPage: number;
  timezoneOffsetWithRespectToUTC: number;
  schedulingBlockReplacements: Record<string, string>;
  unassignedReasonCodeToDescriptionMapping: Record<string, string>;
  needHelpDropdownOptions: Record<string, Record<string, string>>;
  schedulingBlocksList: string[];
  workOrderOttoStatusTypes: Record<string, string>;
  numberOfDatesForSchedulingWorkOrder: number;
  lockResetApiTimer: number;
  lockSessionIdleDisclaimerTimeout: number;
  lockSessionEndTimeout: number;
  idleSessionPromptTimeout: number;
  ottoWikiLink: string;
  labourPlanningQuickSiteLink: string;
}

export const formatAppConfigDataFromCSVFile = (data): iConfigData => {
  const configData: iConfigData = {
    adminTabsAuthorisedGroups: [],
    overviewTabAuthorisedGroups: [],
    scheduleWOTabAuthorisedGroups: [],
    scheduleWorkOrdersTabEnabledSites: [],
    noOfCardsOnOverviewPage: parseInt(data[0].noOfCardsOnOverviewPage) ?? 21,
    timezoneOffsetWithRespectToUTC:
      parseFloat(data[0].timezoneOffsetWithRespectToUTC) ?? 0,
    schedulingBlockReplacements: {},
    unassignedReasonCodeToDescriptionMapping: {},
    schedulingBlocksList: [],
    workOrderOttoStatusTypes: {},
    numberOfDatesForSchedulingWorkOrder:
      parseInt(data[0].numberOfDatesForSchedulingWorkOrder) ?? 4,
    lockResetApiTimer: parseInt(data[0].lockResetApiTimer),
    lockSessionIdleDisclaimerTimeout: parseInt(
      data[0].lockSessionIdleDisclaimerTimeout,
    ),
    lockSessionEndTimeout: parseInt(data[0].lockSessionEndTimeout),
    idleSessionPromptTimeout: parseInt(data[0].idleSessionPromptTimeout),
    ottoWikiLink: data[0].ottoWikiLink,
    labourPlanningQuickSiteLink: data[0].labourPlanningQuickSiteLink ?? '',
    schedulingBlockOverwriteReasons: [],
    ignoredErrorCodes: [],
    overwriteReasonsList: [],
    ottoEnabledSites: [],
    needHelpDropdownOptions: {},
  };
  data.forEach(rowData => {
    // keys with array of strings
    [
      'adminTabsAuthorisedGroups',
      'overviewTabAuthorisedGroups',
      'scheduleWOTabAuthorisedGroups',
      'schedulingBlocksList',
      'schedulingBlockOverwriteReasons',
      'ignoredErrorCodes',
      'overwriteReasonsList',
      'scheduleWorkOrdersTabEnabledSites',
      'ottoEnabledSites',
    ].forEach(key => {
      rowData[key] && configData[key].push(rowData[key]);
    });

    // keys with array of objects
    [
      'unassignedReasonCodeToDescriptionMapping',
      'schedulingBlockReplacements',
      'workOrderOttoStatusTypes',
    ].forEach(configKey => {
      if (rowData[configKey]) {
        const [key, value] = rowData[configKey].split('=');
        configData[configKey][key.trim()] = value.trim();
      }
    });

    // keys with array of nested objects
    ['needHelpDropdownOptions'].forEach(configKey => {
      if (rowData[configKey]) {
        const [parentKey, childKey, value] = rowData[configKey].split('=');
        const trimmedParentKey = parentKey.trim();
        const trimmedChildKey = childKey.trim();
        const trimmedValue = value.trim();

        const configKeyData = configData[configKey];

        if (!configKeyData[trimmedParentKey]) {
          configKeyData[trimmedParentKey] = {};
        }
        if (!configKeyData[trimmedParentKey][trimmedChildKey]) {
          configKeyData[trimmedParentKey][trimmedChildKey] = trimmedValue;
        }
      }
    });
  });

  return configData;
};

export const formCSVFileDataFromFormattedAppConfigData = (data): any => {
  let maxLength = 0;
  Object.keys(data).forEach(field => {
    maxLength = Math.max(data[field].length, maxLength);
  });
  return [...Array(maxLength).keys()].map(index => {
    const dataObject = {};
    Object.keys(data).forEach(key => {
      dataObject[key] = data[key][index] ?? '';
    });
    return dataObject;
  });
};
