import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@amzn/awsui-components-react/polaris/box';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button/';
import Tabs from '@/library/components/atoms/Tabs';
import EditWOSessionManager from '@/library/components/organisms/EditWOSessionManager';
import { appConstants } from '@/library/constants/appConstants';
import { appRoutes } from '@/library/constants/appRoutes';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { formatDate, getUTCDate } from '@/library/utils/dateUtils';
import { useCustomNavigate } from '@/library/utils/hooks/useCustomNavigate';

import { administrationTab, overviewTab, scheduleWOTab } from './tabsConfig';

import './commonHeaderStyles.scss';

export function CommonHeader(): ReactElement {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [tabsConfig, setTabsConfig] = useState([]);
  const { activeSite, ottoAppConfig, userDetails } = useAppSelector(
    state => state.masterDataReducer,
  );

  const { isEditSessionActive, recommendationsMetadata } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const {
    overviewTabAuthorisedGroups,
    scheduleWOTabAuthorisedGroups,
    adminTabsAuthorisedGroups,
    labourPlanningQuickSiteLink,
  } = ottoAppConfig;

  const isSiteEnabledForAdminTab =
    ottoAppConfig.scheduleWorkOrdersTabEnabledSites.includes(
      activeSite?.siteId ?? '',
    );

  const navigate = useCustomNavigate();

  const onChangeTab = ({ detail }): void => {
    navigate.replace(detail.activeTabId);
  };

  useEffect(() => {
    setActiveTab(location.pathname);
    recordEvent(metricNames.otto_pageVisit, { previousPage });
    setPreviousPage(location.pathname);
  }, [location]);

  const isUserAuthorised = (groupOrEmail): boolean => {
    const userGroup = userDetails?.userGroup ?? '';
    const userEmail = userDetails?.email ?? '';

    return (
      groupOrEmail.toLowerCase().trim() === userGroup.toLowerCase().trim() ||
      groupOrEmail.toLowerCase().trim() === userEmail.toLowerCase().trim()
    );
  };

  useEffect(() => {
    const tabs: any = [];
    overviewTabAuthorisedGroups.some(isUserAuthorised) &&
      tabs.push(overviewTab);
    scheduleWOTabAuthorisedGroups.some(isUserAuthorised) &&
      tabs.push(scheduleWOTab);
    adminTabsAuthorisedGroups.some(isUserAuthorised) &&
      tabs.push(administrationTab);
    setTabsConfig(tabs);
  }, [ottoAppConfig]);

  return (
    <div className="title-tabs-container">
      <div className="title-button-container">
        <div className="site-name-container">
          <Box fontSize="heading-xl">
            {activeSite?.siteId || appConstants.appName}
          </Box>
        </div>

        <div className="action-buttons">
          {location.pathname === appRoutes.overview &&
            activeSite?.siteId &&
            labourPlanningQuickSiteLink && (
              <Button
                href={labourPlanningQuickSiteLink}
                iconAlign="left"
                iconName="external"
                target="_blank"
                metricId={metricNames.laborPlanningQuickSiteButton}
              >
                {uiStrings.labourPlanningButtonLabel}
              </Button>
            )}
          {(location.pathname === appRoutes.scheduleWorkOrders ||
            isEditSessionActive) &&
            isSiteEnabledForAdminTab && <EditWOSessionManager />}
        </div>
      </div>
      <div className="tabs-container">
        <Tabs
          tabs={tabsConfig}
          activeTabId={activeTab}
          onChange={onChangeTab}
        />
        {recommendationsMetadata.latestModelIngestedTimestamp &&
          activeTab === scheduleWOTab.id && (
            <span className="run-time-text">
              {uiStrings.lastRunTime}:{' '}
              {formatDate(
                getUTCDate(
                  new Date(
                    recommendationsMetadata.latestModelIngestedTimestamp,
                  ),
                ),
                '{MMM} {DD}, {YYYY} {hh}:{mm} UTC',
              )}
            </span>
          )}
      </div>
    </div>
  );
}
