import React, { ReactElement } from 'react';

import PolarisButtonDropdown, {
  ButtonDropdownProps,
} from '@amzn/awsui-components-react/polaris/button-dropdown';

export interface IButtonDropdownProps extends ButtonDropdownProps {}

export function ButtonDropdown(props: IButtonDropdownProps): ReactElement {
  return <PolarisButtonDropdown {...props} />;
}
